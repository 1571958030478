<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Mass Update Email" sub-title="Changing Customer email in Warranty List">
      <b-col cols="12">
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <br>
        <b-row>
          <b-col md="6">
            <label for="search" class="form-label">Please input old customer email</label>
            <b-form-input
              v-model="oldEmail"
              type="search"
              placeholder="Old Email"
              style="float: right;"
              @input="validateOldEmail"
            />
            <p v-if="!isValidOldEmail" class="text-danger">Please enter a valid email address</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6"> 
            <label for="search" class="form-label">Please input new customer email</label>
            <b-form-input
              v-model="newEmail"
              type="search"
              placeholder="New Email"
              style="float: right;"
              @input="validateNewEmail"
            />
            <p v-if="!isValidNewEmail" class="text-danger">Please enter a valid email address</p>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col cols="12" md="3" sm="3">
            <b-button style="margin-top: 22px;" @click="getWarranty" variant="primary">Search Certificate</b-button>
          </b-col>
          <b-col cols="12" md="3" sm="3" v-if="selectedCert.length > 0 || selectAll == true">
            <b-button style="margin-top: 22px;" @click="massUpdate" variant="success">Mass Update Email</b-button>
          </b-col>
        </b-row>
        <b-row class="hide-on-mobile" v-if="allBox">
          <b-col cols="6">
          </b-col>
          <b-col cols="6" style="display: flex; justify-content: space-between;">
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <div style="display: flex; align-items: center;">
              <span style="margin-right: 10px; white-space: nowrap; font-size: 16px;">Select All Certificate</span>
              <b-form-checkbox v-model="selectAll" />
              </div>
            </b-col>      
          </b-col>
        </b-row>
        <b-row class="hide-on-desktop" v-if="allBox">
          <b-col cols="6" style="display: flex; justify-content: space-between;">
            <b-col cols="6">
              <div style="display: flex; align-items: center;">
              <span style="margin-right: 10px; white-space: nowrap; font-size: 16px;">Select All Certificate</span>
              <b-form-checkbox v-model="selectAll" />
              </div>
            </b-col>      
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">   
            <b-table
            sticky-header="500px"
            :items="allWarranties"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoadingWarranty"
            show-empty
            empty-text="Data Not Found"
            responsive
          >
            <template #cell(customer)="row">             
                {{ `${row.item.nameFirst} ${row.item.nameLast}` }}            
            </template>
            <template #cell(mergeAddress)="row">
                {{ `${row.item.address?row.item.address:''} ${row.item.address2?row.item.address2:''} ${row.item.address3?row.item.address3:''}
                -${row.item.buildingName?row.item.buildingName:''} ` }}
            </template>
            <template #cell(createdAt)="row">
              {{ row.item.createdAt?row.item.createdAt:'' | formatDate }}
            </template>
            <template #cell(dealerCode)="row">
              {{ getDealerCode(row.item) }}
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(action)="row">
                <b-form-checkbox 
                :value="row.item._id"
                v-model="selectedCert"
                /> 
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              :total-rows="totalData"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              style="float : right;"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat, sleep } from "@/utils/utils";

export default {
  data() {
    return {
      selectAll : false,
      search : "",
      isLoading : false,
      oldEmail : "",
      newEmail : "",
      selectedCert: [],
      metadata:{totalPages:0, totalData:0, lastRegisterDate:null},
      allWarranties: [],
      selectedFilter: "Name",
      selectedField: "fullName",
      busyLoadingWarranty: false,
      isValidOldEmail: true, // Inisialisasi nilai validasi email lama
      isValidNewEmail: true, // Inisialisasi nilai validasi email baru
      totalData: 1,
      perPage: 10,
      currentPage: 1,
      allBox : false,
      fields: [
        { key: 'cert', label: 'Certificate'},
        { key: 'customer', label: 'Customer Name'},
        { key: 'email', label: 'Customer Email'},
        { key: 'installerName', label: 'Installer'},
        { key: 'postal', label: 'Postal'},
        { key: "mergeAddress", label: "Address", thStyle: {minWidth: '250px'}},
        { key: 'registerStatus', label: 'Status'},
        { key: 'createdAt', label: 'Registration Date' },
        { key: "action", label: "Select Certificate"},
      ],
    }
  },
  computed: {
    permission() {
      let result = userAccess("Mass Update Email", "utilitytools_menu");
      return result;
    },
  },
  created() {
    document.title = "Mass Update Email | RSP";
  },
  mounted() {
  },
  watch: {
    selectAll(value){
      if(value){
        this.selectedCert = this.allWarranties.map(el => el._id)
      }else{
        this.selectedCert = []
      }
    }
  },
  methods: {
    ...mapActions({
      getAllWarranty: "warranty/getExternalWarranty",
      massUpdateEmail: "warranty/massUpdateEmail"
    }),
    getWarranty(){
      if(this.oldEmail === "" || this.newEmail === ""){
        this.$bvToast.toast("Please fill in old email and new email", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        return;
      }
      this.getAllWarranty({
        email: this.oldEmail,
        page:1
      })
      .then((data) => {
        this.allBox = true
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
        this.allWarranties = data.data;
      })
      .catch((err) => {
        console.log({ err });
      });
    },
    processMetadata(metadata) {
      this.totalData = metadata.totalData;
      this.metadata.totalPages = metadata.totalPages
      this.metadata.totalData = metadata.totalData
      this.metadata.lastRegisterDate = metadata.lastRegisterDate
    },
    getDealerCode(data) {
      if (data.items.length > 0) {
        return data.items[0].dealerCode;
      }

      return undefined;
    },
    onPageChange(page) {
      this.getAllWarranty({
        email: this.oldEmail,
        page:page
      })
      .then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
        this.allWarranties = data.data;
      })
      .catch((err) => {
        console.log({ err });
      });
    },
    validateOldEmail() {
      // Ekspresi reguler untuk memeriksa format email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Lakukan validasi dengan ekspresi reguler
      this.isValidOldEmail = emailRegex.test(this.oldEmail);
    },
    validateNewEmail() {
      // Ekspresi reguler untuk memeriksa format email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Lakukan validasi dengan ekspresi reguler
      this.isValidNewEmail = emailRegex.test(this.oldEmail);
    },
    massUpdate(){
      let payload = {
        oldEmail : this.oldEmail,
        newEmail : this.newEmail,
        selectAll : this.selectAll,
        arrayId : this.selectedCert
      }
      this.massUpdateEmail(payload).then((data) => {
        this.$bvToast.toast("Successfully Mass Update Email", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.selectAll = false
        this.selectedCert = []
        this.allBox = true
        this.getAllWarranty({
          email: this.oldEmail,
          page:1
        })
        .then((data) => {
          this.allBox = true
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata);
          this.allWarranties = data.data;
        })
        .catch((err) => {
          console.log({ err });
        });

      })
      .catch((err) => {
        console.log({ err });
        this.$bvToast.toast("Failed to mass update email :" + err, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      });
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
